<template>
  <content justify="center">
    <!-- DIALOGO RESPUESTA UPDATES PEDIDOS -->
    <v-dialog
      persistent
      v-model="dialogoRespuestaUpdates"
      max-width="700"
      scrollable
    >
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong v-if="obteniendo_actualizaciones"
            >Obteniendo actualizaciones...</strong
          >
          <strong v-else-if="enviando_pedidos"
            >Actualizando pedidos en inventario...</strong
          >
          <strong v-else>RESULTADO</strong>

          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text style="height: 400px">
          <v-row>
            <v-col>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Pedido</th>
                      <th class="text-left">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultadoUpdates" :key="item.orderId">
                      <td>{{ item.orderId }}</td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            class="mt-2"
            color="info"
            @click="dialogoRespuestaUpdates = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO RESPUESTA UPDATES PEDIDOS -->

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <h3>
                <v-icon size="50" color="info">mdi-update</v-icon>
                Actualizar pedidos
              </h3>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="actualizaciones"
                item-value="id"
                v-model="updateSeleccionado"
                item-text="text"
                label="Selecciona"
                solo
                :rules="updatesRules"
                :loading="cargandoActualizaciones"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-textarea
            ref="buscador"
            v-model="listaPedidos"
            outlined
            name="input-7-4"
            label=""
            value=""
            required
            :rules="pedidosRules"
          ></v-textarea>
          <v-row justify="center">
            <v-btn @click="updatePedidos" color="success" :disabled="!validar">
              Ingresar
            </v-btn>
            <v-btn class="ml-4" color="warning" @click="limpiarDatos">
              Limpiar datos
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import moment from "moment";

export default {
  data() {
    return {
      updatesRules: [(v) => !!v || "Debes seleccionar una actualizacion"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      updateSeleccionado: "",
      listaPedidos: "",
      dialogoRespuestaUpdates: false,
      colorProgreso: "",
      validar: false,
      cargandoActualizaciones: false,
      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      itemsFallidos: [],
      resultadoUpdates: [],
      actualizaciones: [
        { text: "rutas", id: "61d74780c11ae9298749fe02" },
        { text: "Devolucion", id: "609d60cca9bceb469d351982" },
        { text: "Agendamiento", id: "609d60cc0322222a0697708a" },
        { text: "Zonificado", id: "61e974e2532904d2a6a35cf3" },
      ],
      obteniendo_actualizaciones: false,
      enviando_pedidos: false,
    };
  },

  methods: {
    datosUsuario() {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid,
      };
    },
    limpiarDatos() {
      this.$refs.enviarFormulario.reset();
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    getTimewindow(task) {
      let cleanTimestamp = null;

      let init_ts = new Date(
        moment().format("YYYY-MM-DD") + " 00:00:00"
      ).getTime();
      let end_ts = new Date(
        moment().add(1, "days").format("YYYY-MM-DD") + " 23:59:59"
      ).getTime();

      if (
        task.dataFound === 0 ||
        !task.time_window ||
        !task.time_window.timestamp ||
        task.time_window.timestamp.length === 0
      ) {
        cleanTimestamp = {
          from: 0,
          to: 23,
          description: "NO TW",
          timestamp: [[init_ts, end_ts]],
        };
      } else {
        cleanTimestamp = task.time_window;
      }

      return cleanTimestamp;
    },
    async updatePedidos() {
      try {
        this.colorProgreso = "white";
        this.dialogoRespuestaUpdates = true;
        this.resultadoUpdates = [];
        var arreglo_pedidos = this.listaPedidos.split(/\r\n|\n|\r/);
        var estado_name = "Allocated";
        var estado_id = "6087137b05bfeb80c52f5efa";
        var posicion_name = "";
        var posicion_id = this.updateSeleccionado;
        var email = this.datosUsuario().email_user;
        let array_tasks = null;

        for (const i in this.actualizaciones) {
          if (this.updateSeleccionado == this.actualizaciones[i].id) {
            posicion_name = this.actualizaciones[i].text;
          }
        }

        console.log("CANT DE PEDIDOS: " + arreglo_pedidos.length);
        console.log(`Obteniendo actualizaciones...`);
        this.obteniendo_actualizaciones = true;
        if (arreglo_pedidos.length !== 0) {
          var consultaPedido = await axios.post(
            "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/consultarPedido",
            arreglo_pedidos
          );

          let tasks = consultaPedido.data;

          array_tasks = tasks.map((task) => {
            return {
              codigo:
                task.order_id_aux === ""
                  ? task.order_id_task
                  : task.order_id_aux,
              code_task: task.code_task || "",
              estado: estado_id,
              estado_name: estado_name,
              posicion_name: posicion_name,
              posicion: posicion_id,
              id_zona: task.zone_id || "",
              latitud: task.latitude_task || "",
              longitud: task.longitude_task || "",
              nombre_zona: task.zone_name || "",
              direccion: task.address_task || "",
              email: email,
              time_window: this.getTimewindow(task),
              rayo: "Bodega",
              _id: "-",
              emailResponsable: this.datosUsuario().email_user,
              nameResponsable: this.datosUsuario().name_user,
              uidResponsable: this.datosUsuario().uid,
              sistemaResponsable: "warehouse.rayoapp.com",
              epochResponsable: new Date().getTime(),
            };
          });
        }
        this.obteniendo_actualizaciones = false;
        this.enviando_pedidos = true;
        console.log("LO QUE ENVÍO: ", array_tasks);
        console.log("Enviando updates...");

        var respuestaPeticion = await axios.post(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/update",
          array_tasks
        );
        this.enviando_pedidos = false;
        console.log(respuestaPeticion.status);
        console.log("LO QUE RECIBO: ", respuestaPeticion.data);

        for (const l in respuestaPeticion.data) {
          var order_id = respuestaPeticion.data[l].order_id;
          var observacion = respuestaPeticion.data[l].result;

          let resultado = {
            orderId: `${order_id}`,
            observacion: `${observacion}`,
          };
          this.resultadoUpdates.push(resultado);
        }

        this.colorProgreso = "info lighten-1";
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.datosUsuario();
  },
};
</script>
